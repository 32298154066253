import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import logo from '../assets/logo.jpg';


const pages = ['Products', 'Pricing', 'Tutorial','Our Website'];
let settings = ['Profile', 'Account','Dashboard', 'Logout'];
function ResponsiveAppBar({setSelectedView, name, dash = false}) {
  
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [openTutorialDropdown,setOpenTutorialDropdown] = React.useState(false);
  const [anchorElTutorial, setAnchorElTutorial] = useState(null);
  const isMenuOpen = Boolean(anchorElTutorial);
  if (dash) settings = ['Dashboard'];
  
  const handleMenuOpen = (event) => {
    setAnchorElTutorial(event.currentTarget); // Set the anchor element for the dropdown
  };

  const handleMenuClose = () => {
    setAnchorElTutorial(null); // Close the dropdown
  };
  // const [name,setName] = React.useState("");
  const navigate = useNavigate();

  const toggleDropdown = () => setOpenTutorialDropdown((prev) => !prev);
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleMenuButtons = (button) => {
    if(dash && button == "Dashboard")
    {
      dash = false;
      settings = ['Profile', 'Account','Dashboard', 'Logout'];
      navigate("/dashboard");
      return;
    }
    switch (button) {
      case "Logout":
        localStorage.removeItem("jwttoken");
        sessionStorage.removeItem("jwttoken");
        handleCloseUserMenu();
        navigate("/admin/login");
        break;
      case "Dashboard":
        console.log(button);  
        setSelectedView("profile");
        handleCloseUserMenu();
        break;
      case "Profile":
        console.log(button);  
        setSelectedView("profile");
        handleCloseUserMenu();
        break;
      case "Account":
        console.log(button);  
        setSelectedView("settings");
        handleCloseUserMenu();
        break;
      case "Tutorial":
        setSelectedView("tutorial");
        break;
      case "Our Website":
        window.open('https://vavetek.ai/','_blank');
        break;
      // case "Pricing":
      //   setSelectedView("pricing");
      //   break;
      case "Products":
        setSelectedView("products");
        break;
      break;
      default:
        break;
    }
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <AppBar position="fixed" elevation={0} sx={{paddingLeft:"10px",display:"flex",flexDirection:"row",justifyContent:"space-between", width: "100vw",height:"90px",alignItems:"center",backgroundColor: '#FFFFFF', zIndex: (theme) => theme.zIndex.drawer + 1 }}>
      <Box display="flex" flexDirection="column" width="100vw">
        <Toolbar disableGutters >
          <img src={logo} alt="Logo" style={{ height: '60px', marginRight: '16px', marginLeft: '16px'}} />
          <Typography
            variant="h5"
            noWrap
            component="a"
            href="/dashboard"
            
            // onClick={() => window.open('https://www.vavetek.ai', '_blank')}
            // onClick={}
            sx={{
              cursor: "pointer",
              mr: 2,
              fontSize: "25px",
              display: { xs: 'none', md: 'flex' },
              fontFamily: 'Poppins, sans-serif',
              fontWeight: 600,
              letterSpacing: '2px',
              color: '#CC9A39', // SaddleBrown for the title
              textTransform: 'uppercase',
              textDecoration: 'none',
            }}
          >
            VAVETEK.AI
          </Typography>

          <Box id="menu-appbar box" sx={{flexGrow: 1, display: "flex" ,height:"100%"}}>
            {/* <IconButton
              size="large"
              aria-label="menu"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              sx={{ color: '#8b4513' }} // Warm brown for the menu icon
            >
              <MenuIcon />
            </IconButton> */}
            {/* <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{ display: { xs: 'block', md: 'none' } }}
            > */}
              <Box display="flex" flexDirection="row" gap="80px" marginLeft="70px">
                {pages.map((page) => (
                  <MenuItem key={page} onClick={() => handleMenuButtons(page)}
                    sx={{ 
                      height : "100%",
                      borderRadius: '8px', // Apply rounded corners
                      transition: "all 1s ease-in-out", // Smooth transition for hover
                      '&:hover': {
                        borderRadius: '8px', // Maintain rounded corners on hover
                        background: "linear-gradient(to bottom, #e7d3a8, #CC9A39)", // Slightly darker on hover
                        boxShadow: "0px 6px 8px rgba(0, 0, 0, 0.15)",
                        animationDelay: "1s"
                      },
                      "&:active": {
                        background: "linear-gradient(to bottom, #e7d3a8, #d5bc88)", // Darker gradient when clicked
                        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                        transform: "scale(0.98)", // Slight shrink effect
                      },
                    }}
                  >
                      <Typography
                        sx={{
                          textAlign: 'center',
                          fontFamily: 'Poppins, sans-serif',
                          fontWeight: '400',
                          color: '#2C2A2A',
                          fontSize: "18px"
                        }}
                      >
                        {page}
                      </Typography>
                  </MenuItem>
                  ))}
                </Box>
              </Box>
          
          {/* Responsive Menu Items for Large Screens */}
          {/* <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {pages.map((page) => (
              <Button
                key={page}
                onClick={handleCloseNavMenu}
                sx={{
                  my: 2,
                  color: '#5c4033',
                  display: 'block',
                  fontFamily: 'Poppins, sans-serif',
                  fontWeight: '500',
                  letterSpacing: '.05em',
                  textTransform: 'capitalize',
                  '&:hover': {
                    color: '#8b4513', // Darker shade of brown for hover effect
                  },
                }}
              >
                {page}
              </Button>
            ))}
          </Box> */}

          {/* User Avatar and Settings Menu */}
          <Box sx={{ flexGrow: 0, display: "flex",flexDirection: "row",justifyContent:"flex-end", margin : "40px",gap :"15px"}}>
            <Typography
              sx = {{fontWeight: "500",fontSize: "18px",lineHeight:"36px",color: "#2C2A2A"}}
            >
              Hi, {name}
            </Typography>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar alt="User Avatar" src="/static/images/avatar/2.jpg"  />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <MenuItem key={setting} onClick={() => handleMenuButtons(setting)}>
                  <Typography
                    sx={{
                      textAlign: 'center',
                      fontFamily: 'Poppins, sans-serif',
                      fontWeight: '500',
                      color: '#5c4033', // Darker brown for the settings text
                    }}
                  >
                    {setting}
                  </Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Toolbar> 
      </Box>
    </AppBar>
  );
}

export default ResponsiveAppBar;
