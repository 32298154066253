import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, TextField, Typography, Button } from '@mui/material';
import { loadAirwallex, redirectToCheckout } from 'airwallex-payment-elements';

const AddTokens = () => {
  const [tokenCount, setTokenCount] = useState('');
  const [price, setPrice] = useState(0);
  const [breakdown, setBreakdown] = useState('');

  // Coupon-related states
  const [couponCode, setCouponCode] = useState('');
  const [couponType, setCouponType] = useState(''); // "discount" or "buy1getx"
  const [discount, setDiscount] = useState(0);      // e.g., 25 for 25%
  const [buy1GetX, setBuy1GetX] = useState(0);      // e.g., 2 for "Buy 1 Get 2"
  
  // Final price / token logic
  const [finalPrice, setFinalPrice] = useState(0);
  const [effectiveTokenCount, setEffectiveTokenCount] = useState(0);

  // Control flow states
  const [isPriceFetched, setIsPriceFetched] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    // Initialize Airwallex
    loadAirwallex({
      env: 'demo', // Use 'prod' in production
      origin: window.location.origin,
    });
  }, []);

  /**
   * Fetch the price from the backend for the current tokenCount
   */
  const fetchPrice = async () => {
    // If tokenCount is invalid, we don't attempt to fetch
    if (!tokenCount || isNaN(tokenCount) || tokenCount <= 0) {
      alert('Please enter a valid token quantity greater than 0.');
      return;
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/api/price/calculate`,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ tokenCount: Number(tokenCount) }),
        }
      );

      const data = await response.json();

      if (data.success) {
        setPrice(data.totalPrice);
        setBreakdown(data.breakdown);
        setFinalPrice(data.totalPrice);       // Initially, final = base price
        setEffectiveTokenCount(tokenCount);   // Initially, effective tokens = what user typed
        setCouponType('');                    // Reset coupon usage
        setDiscount(0);
        setBuy1GetX(0);
        setIsPriceFetched(true);
      } else {
        alert(data.message);
        setPrice(0);
        setBreakdown('');
        setFinalPrice(0);
        setEffectiveTokenCount(0);
        setCouponType('');
        setDiscount(0);
        setBuy1GetX(0);
        setIsPriceFetched(false);
      }
    } catch (error) {
      console.error('Error fetching price:', error);
      alert('Failed to fetch price. Please try again.');
      setIsPriceFetched(false);
    }
  };

  /**
   * Handle token count change
   */
  const handleTokenChange = (e) => {
    const value = e.target.value;
    setTokenCount(value);

    // When user changes token count, reset everything
    setPrice(0);
    setBreakdown('');
    setFinalPrice(0);
    setEffectiveTokenCount(0);
    setCouponType('');
    setDiscount(0);
    setBuy1GetX(0);
    setIsPriceFetched(false);
  };

  /**
   * Apply a percentage discount to the base price
   */
  const applyDiscount = (basePrice, discountPercentage) => {
    const discountedPrice = basePrice - (basePrice * discountPercentage) / 100;
    setFinalPrice(discountedPrice);
  };

  /**
   * Apply a buy1getx coupon to the current token count
   * - The user pays for "tokenCount" tokens
   * - but gets "tokenCount * (1 + buy1getx)" tokens
   */
  const applyBuy1GetX = (basePrice, buyX) => {
    // The price remains the same (basePrice)
    setFinalPrice(basePrice);
    // The effective token count is tokenCount * (1 + buyX)
    const totalTokens = tokenCount * (1 + buyX);
    setEffectiveTokenCount(totalTokens);
  };

  /**
   * Validate the coupon code by calling the coupon API
   */
  const validateCoupon = async () => {
    if (!couponCode) {
      alert('Please enter a valid coupon code.');
      return;
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/api/user/coupon/validate`,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ code: couponCode }),
        }
      );

      const data = await response.json();

      if (data.success) {
        // Evaluate type: discount or buy1getx
        if (data.type === 'discount') {
          setCouponType('discount');
          setDiscount(data.discountPercentage);
          setBuy1GetX(0); // reset
          applyDiscount(price, data.discountPercentage); // recalc final price
          setEffectiveTokenCount(tokenCount); // total tokens remain the same
          alert(`Coupon applied! ${data.discountPercentage}% discount.`);
        } else if (data.type === 'buy1getx') {
          setCouponType('buy1getx');
          setBuy1GetX(data.buy1GetX);
          setDiscount(0); // reset
          applyBuy1GetX(price, data.buy1GetX);
          alert(`Coupon applied! Buy 1 Get ${data.buy1GetX} free.`);
        } else {
          alert('Unknown coupon type.');
        }
      } else {
        // invalid/expired coupon
        alert(data.message);
        // reset everything back
        setCouponType('');
        setDiscount(0);
        setBuy1GetX(0);
        setFinalPrice(price);  // revert to base price
        setEffectiveTokenCount(tokenCount);
      }
    } catch (error) {
      console.error('Error validating coupon:', error);
      alert('Failed to validate coupon. Please try again.');
    }
  };

  /**
   * Navigate to the Hosted Payment Page (Airwallex).
   */
  const handlePayment = async () => {
    try {
      const jwt = localStorage.getItem('jwttoken')
        ? localStorage.getItem('jwttoken')
        : sessionStorage.getItem('jwttoken');

      // The user will pay 'finalPrice'
      // The user will get 'effectiveTokenCount' tokens
      // If discount: user pays less for the same token count
      // If buy1getx: user pays the same base price, but gets more tokens
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/api/payment/create-payment-intent`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${jwt}`,
          },
          body: JSON.stringify({
            tokenCount: Number(effectiveTokenCount), // total tokens user ends up with
            amount: finalPrice,                       // The final price after discount or buy1getx
            currency: 'USD',
            orderId: `order_${Date.now()}`,
            returnUrl: `${window.location.origin}/payment-result`,
          }),
        }
      );

      const data = await response.json();

      if (data.id && data.client_secret) {
        // Redirect to Hosted Payment Page
        redirectToCheckout({
          env: 'demo',
          intent_id: data.id,
          client_secret: data.client_secret,
          currency: 'USD',
          successUrl: `${window.location.origin}/payment-success`,
          failUrl: `${window.location.origin}/payment-failure`,
          logoUrl: 'https://your-logo-url.com/logo.png',
          locale: 'en',
        });
      } else {
        alert('Failed to generate PaymentIntent. Please try again.');
      }
    } catch (error) {
      console.error('Error generating PaymentIntent:', error);
      alert('An error occurred while generating the PaymentIntent.');
    }
  };

  /**
   * Navigate back to the dashboard
   */
  const handleBackToDashboard = () => {
    navigate('/dashboard');
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '30px',
        backgroundColor: '#FFFFFF',
        borderRadius: '10px',
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
        maxWidth: '500px',
        margin: 'auto',
        marginTop: '50px',
      }}
    >
      <Typography
        variant="h5"
        sx={{
          fontWeight: '600',
          color: '#000000',
          fontFamily: 'Poppins, sans-serif',
          marginBottom: '20px',
        }}
      >
        Add Tokens
      </Typography>

      <Typography
        variant="body1"
        sx={{
          color: '#777676',
          fontFamily: 'Poppins, sans-serif',
          marginBottom: '20px',
          textAlign: 'center',
        }}
      >
        Select the number of tokens you'd like to purchase. Pricing tiers:
        <ul style={{ textAlign: 'left' }}>
          <li>1-1000 tokens: $0.50 per token</li>
          <li>1001-5000 tokens: $0.48 per token</li>
          <li>5001-20000 tokens: $0.45 per token</li>
          <li>20001+ tokens: $0.40 per token</li>
        </ul>
      </Typography>

      {/* Token Input */}
      <TextField
        label="Enter Token Quantity"
        type="number"
        value={tokenCount}
        onChange={handleTokenChange}
        sx={{
          marginBottom: '20px',
          width: '100%',
          fontFamily: 'Poppins, sans-serif',
        }}
      />

      {/* Calculate Price */}
      <Button
        variant="outlined"
        onClick={fetchPrice}
        sx={{
          marginBottom: '20px',
          width: '100%',
          backgroundColor: '#FFFFFF',
          borderColor: '#E0AD49',
          '&:hover': { backgroundColor: '#E0AD49', color: '#FFFFFF' },
        }}
      >
        Calculate Price
      </Button>

      {/* Coupon Code Input & Apply Button */}
      <TextField
        label="Enter Coupon Code"
        value={couponCode}
        onChange={(e) => setCouponCode(e.target.value)}
        disabled={!isPriceFetched} // disabled until we have a base price
        sx={{
          marginBottom: '20px',
          width: '100%',
          fontFamily: 'Poppins, sans-serif',
        }}
      />
      <Button
        variant="outlined"
        onClick={validateCoupon}
        disabled={!isPriceFetched} // disabled until price is fetched
        sx={{
          marginBottom: '20px',
          width: '100%',
          backgroundColor: '#FFFFFF',
          borderColor: '#E0AD49',
          '&:hover': { backgroundColor: '#E0AD49', color: '#FFFFFF' },
        }}
      >
        Apply Coupon
      </Button>

      {/* Price & Discount Info */}
      <Typography
        variant="body1"
        sx={{
          marginBottom: '10px',
          color: '#000000',
          fontFamily: 'Poppins, sans-serif',
        }}
      >
        Base Price: <strong>${price.toFixed(2)}</strong>
      </Typography>

      {/* If discount type */}
      {couponType === 'discount' && discount > 0 && (
        <Typography
          variant="body1"
          sx={{
            marginBottom: '10px',
            color: '#000000',
            fontFamily: 'Poppins, sans-serif',
          }}
        >
          Discount: {discount}% → You Pay: <strong>${finalPrice.toFixed(2)}</strong>
        </Typography>
      )}

      {/* If buy1getx type */}
      {couponType === 'buy1getx' && buy1GetX > 0 && (
        <Typography
          variant="body1"
          sx={{
            marginBottom: '10px',
            color: '#000000',
            fontFamily: 'Poppins, sans-serif',
          }}
        >
          Buy 1 Get {buy1GetX} Free! You pay for <strong>{tokenCount}</strong> tokens → 
          receive a total of <strong>{effectiveTokenCount}</strong> tokens
          <br />
          Final Price: <strong>${finalPrice.toFixed(2)}</strong>
        </Typography>
      )}

      {/* If no coupon applied yet, just show final price same as base price */}
      {couponType === '' && isPriceFetched && discount === 0 && buy1GetX === 0 && (
        <Typography
          variant="body1"
          sx={{
            marginBottom: '10px',
            color: '#000000',
            fontFamily: 'Poppins, sans-serif',
          }}
        >
          Final Price: <strong>${finalPrice.toFixed(2)}</strong>
        </Typography>
      )}

      {/* Breakdown text from server */}
      <Typography
        variant="body2"
        sx={{
          marginBottom: '20px',
          color: '#777676',
          fontFamily: 'Poppins, sans-serif',
          textAlign: 'center',
        }}
      >
        {breakdown}
      </Typography>

      {/* Action Buttons */}
      <Box
        sx={{
          display: 'flex',
          gap: 2,
          width: '100%',
          justifyContent: 'space-between',
          marginTop: '20px',
        }}
      >
        {/* Back to Dashboard */}
        <Button
          variant="outlined"
          onClick={handleBackToDashboard}
          sx={{
            backgroundColor: '#FFFFFF',
            color: '#E0AD49',
            fontFamily: 'Poppins, sans-serif',
            fontWeight: '500',
            textTransform: 'none',
            width: '48%',
            height: '50px',
            borderRadius: '10px',
            border: '1px solid #E0AD49',
            '&:hover': {
              backgroundColor: '#E0AD49',
              color: '#000000',
            },
          }}
        >
          Back to Dashboard
        </Button>

        {/* Proceed to Payment */}
        <Button
          variant="contained"
          onClick={handlePayment}
          disabled={!isPriceFetched || tokenCount <= 0 || isNaN(tokenCount)}
          sx={{
            backgroundColor: '#E0AD49',
            color: '#000000',
            fontFamily: 'Poppins, sans-serif',
            fontWeight: '500',
            textTransform: 'none',
            width: '48%',
            height: '50px',
            borderRadius: '10px',
            '&:hover': {
              backgroundColor: '#FFFFFF',
              color: '#000000',
              border: '1px solid #E0AD49',
            },
          }}
        >
          Proceed to Payment
        </Button>
      </Box>
    </Box>
  );
};

export default AddTokens;
