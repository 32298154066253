import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Button,
  CircularProgress,
  Alert,
  IconButton,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

const CouponManagement = () => {
  const [coupons, setCoupons] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [newCoupon, setNewCoupon] = useState({
    code: '',
    discountPercentage: '',
    buy1GetX: '',
    expiryDate: '',
  });
  const [successMessage, setSuccessMessage] = useState('');

  // Fetch existing coupons
  const fetchCoupons = async () => {
    try {
      setLoading(true);
      const jwt = localStorage.getItem('jwttoken');
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/admin/admincoupon/all-coupons`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      });

      if (!response.ok) {
        throw new Error(`Failed to fetch coupons: ${response.statusText}`);
      }

      const data = await response.json();
      setCoupons(data.coupons || []);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCoupons();
  }, []);

  // Handle adding new coupon
  const handleAddCoupon = async () => {
    try {
      const jwt = localStorage.getItem('jwttoken');
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/admin/admincoupon/add-coupon`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${jwt}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(newCoupon),
      });

      if (!response.ok) {
        throw new Error(`Failed to add coupon: ${response.statusText}`);
      }

      setSuccessMessage('Coupon added successfully!');
      setNewCoupon({ code: '', discountPercentage: '', buy1GetX: '', expiryDate: '' });
      fetchCoupons();
    } catch (err) {
      setError(err.message);
    }
  };

  // Handle deleting a coupon
  const handleDeleteCoupon = async (code) => {
    try {
      const jwt = localStorage.getItem('jwttoken');
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/admin/admincoupon/delete-coupon/${code}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      });

      if (!response.ok) {
        throw new Error(`Failed to delete coupon: ${response.statusText}`);
      }

      setSuccessMessage('Coupon deleted successfully!');
      fetchCoupons();
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <Box sx={{ p: 4 }}>
      <Typography variant="h4" sx={{ mb: 3, fontWeight: 'bold', color: '#000000' }}>
        Coupon Management
      </Typography>

      {error && (
        <Alert severity="error" sx={{ mb: 2, borderRadius: '10px' }}>
          {error}
        </Alert>
      )}
      {successMessage && (
        <Alert severity="success" sx={{ mb: 2, borderRadius: '10px' }}>
          {successMessage}
        </Alert>
      )}

      {/* Add New Coupon */}
      <Paper sx={{ p: 3, mb: 3, borderRadius: '10px', backgroundColor: '#F7F7F7' }}>
        <Typography variant="h6" sx={{ mb: 2 }}>
          Add New Coupon
        </Typography>
        <TextField
          label="Coupon Code"
          value={newCoupon.code}
          onChange={(e) => setNewCoupon({ ...newCoupon, code: e.target.value })}
          fullWidth
          sx={{ mb: 2 }}
        />
        <TextField
          label="Discount Percentage"
          type="number"
          value={newCoupon.discountPercentage}
          onChange={(e) => setNewCoupon({ ...newCoupon, discountPercentage: e.target.value })}
          fullWidth
          sx={{ mb: 2 }}
        />
        <TextField
          label="Buy 1 Get X"
          type="number"
          value={newCoupon.buy1GetX}
          onChange={(e) => setNewCoupon({ ...newCoupon, buy1GetX: e.target.value })}
          fullWidth
          sx={{ mb: 2 }}
        />
        <TextField
          label="Expiry Date"
          type="date"
          InputLabelProps={{ shrink: true }}
          value={newCoupon.expiryDate}
          onChange={(e) => setNewCoupon({ ...newCoupon, expiryDate: e.target.value })}
          fullWidth
          sx={{ mb: 2 }}
        />
        <Button
          variant="contained"
          sx={{
            backgroundColor: '#CC9A39',
            color: '#FFFFFF',
            '&:hover': { backgroundColor: '#B8860B' },
          }}
          onClick={handleAddCoupon}
        >
          Add Coupon
        </Button>
      </Paper>

      {/* Existing Coupons */}
      <Paper sx={{ p: 3, borderRadius: '10px', backgroundColor: '#FFFFFF' }}>
        <Typography variant="h6" sx={{ mb: 2 }}>
          Existing Coupons
        </Typography>
        {loading ? (
          <CircularProgress />
        ) : (
          <TableContainer>
            <Table>
              <TableHead
                sx={{
                  backgroundColor: '#CC9A39',
                  '& .MuiTableCell-root': { color: '#FFFFFF', fontWeight: 'bold' },
                }}
              >
                <TableRow>
                  <TableCell>Code</TableCell>
                  <TableCell>Discount (%)</TableCell>
                  <TableCell>Buy 1 Get X</TableCell>
                  <TableCell>Expiry Date</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {coupons.map((coupon, index) => (
                  <TableRow key={index}>
                    <TableCell>{coupon.code}</TableCell>
                    <TableCell>{coupon.discountPercentage || 'N/A'}</TableCell>
                    <TableCell>{coupon.buy1GetX || 'N/A'}</TableCell>
                    <TableCell>
                      {new Date(coupon.expiryDate).toLocaleDateString('en-US')}
                    </TableCell>
                    <TableCell>
                      <IconButton
                        onClick={() => handleDeleteCoupon(coupon.code)}
                        color="error"
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
                {coupons.length === 0 && (
                  <TableRow>
                    <TableCell colSpan={5} align="center">
                      No coupons found.
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Paper>
    </Box>
  );
};

export default CouponManagement;
