import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Typography,
  CircularProgress,
  TextField,
  Alert,
  Paper,
  Grid,
  AppBar,
  Toolbar,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import UserPaymentHistoryView from './UserPaymentHistoryView';
import UserProjectHistoryView from './UserProjectHistoryView';

const UserManagement = () => {
  const { email } = useParams();
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [actionLoading, setActionLoading] = useState(false);
  const [tokenChange, setTokenChange] = useState(0);
  const [tokenHistory, setTokenHistory] = useState([]);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchUser() {
      try {
        setLoading(true);
        const jwt = localStorage.getItem('jwttoken');
        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/api/admin/adminuserdata/user-details/${email}`,
          {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${jwt}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error(`Failed to fetch user: ${response.status}`);
        }

        const json = await response.json();
        json.data.createdAt = json.createdAt;
        setUser(json.data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    }

    fetchUser();
  }, [email]);

  useEffect(() => {
    async function fetchTokenHistory() {
      try {
        const jwt = localStorage.getItem('jwttoken');
        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/api/admin/admintoken/history?email=${email}`,
          {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${jwt}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error('Failed to fetch token history.');
        }

        const json = await response.json();
        setTokenHistory(json.history || []);
      } catch (err) {
        setError(err.message);
      }
    }

    fetchTokenHistory();
  }, [email]);

  const formatDate = (timestamp) => {
    if (!timestamp) {
      return 'N/A';
    }
  
    if (timestamp.seconds) {
      // Firestore Timestamp
      return new Date(timestamp.seconds * 1000).toLocaleString();
    }
  
    if (timestamp instanceof Date) {
      // Date object
      return timestamp.toLocaleString();
    }
  
    return 'Invalid Date';
  };

  const handleDeleteUser = async () => {
    try {
      setActionLoading(true);
      setError(null);
      setSuccess(null);

      const jwt = localStorage.getItem('jwttoken');
      const endpoint = `${process.env.REACT_APP_API_BASE_URL}/api/admin/adminuserdata/delete-user-by-id/${user.id}`;
      const response = await fetch(endpoint, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      });

      if (!response.ok) {
        throw new Error('Failed to delete user.');
      }

      const json = await response.json();
      setSuccess(json.message);
      navigate('/admin/dashboard');
    } catch (err) {
      setError(err.message);
    } finally {
      setActionLoading(false);
      setDeleteDialogOpen(false);
    }
  };

  const handleTokenOperation = async (operation) => {
    try {
      setActionLoading(true);
      setError(null);
      setSuccess(null);

      const jwt = localStorage.getItem('jwttoken');
      const endpoint = `${process.env.REACT_APP_API_BASE_URL}/api/admin/admintoken/${operation}`;
      const response = await fetch(endpoint, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${jwt}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, tokens: parseInt(tokenChange) }),
      });

      if (!response.ok) {
        throw new Error(`Failed to ${operation} tokens.`);
      }

      const json = await response.json();
      setUser((prev) => ({ ...prev, bamrocTokens: json.updatedTokens }));
      setSuccess(json.message);
    } catch (err) {
      setError(err.message);
    } finally {
      setActionLoading(false);
    }
  };

  const handleAlgorithmAccess = async (enable) => {
    try {
      setActionLoading(true);
      setError(null);
      setSuccess(null);

      const jwt = localStorage.getItem('jwttoken');
      const endpoint = `${process.env.REACT_APP_API_BASE_URL}/api/admin/adminuserdata/${
        enable ? 'enable-algorithm-access' : 'disable-algorithm-access'
      }`;
      const response = await fetch(endpoint, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${jwt}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      if (!response.ok) {
        throw new Error(`Failed to ${enable ? 'enable' : 'disable'} algorithm access.`);
      }

      const json = await response.json();
      setUser((prev) => ({ ...prev, algorithmAccess: enable }));
      setSuccess(json.message);
    } catch (err) {
      setError(err.message);
    } finally {
      setActionLoading(false);
    }
  };

  if (loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '70vh',
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (!user) {
    return (
      <Typography color="error" sx={{ textAlign: 'center', mt: 4 }}>
        User not found
      </Typography>
    );
  }

  return (
    <>
      <AppBar position="fixed" sx={{ backgroundColor: '#CC9A39', color: '#000' }}>
        <Toolbar>
          <IconButton edge="start" color="inherit" aria-label="menu" sx={{ mr: 2 }}>
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" sx={{ flexGrow: 1 }}>
            User Management
          </Typography>
        </Toolbar>
      </AppBar>

      <Box sx={{ p: 4, mt: 8 }}>
        <Typography variant="h4" sx={{ mb: 3, fontWeight: 'bold', color: '#000000' }}>
          Manage User
        </Typography>

        {error && (
          <Alert severity="error" sx={{ mb: 2, borderRadius: '10px' }}>
            {error}
          </Alert>
        )}
        {success && (
          <Alert severity="success" sx={{ mb: 2, borderRadius: '10px' }}>
            {success}
          </Alert>
        )}

        {/* User Information and Actions */}
        <Grid container spacing={3}>
          {/* User Details */}
          <Grid item xs={12} md={6}>
            <Paper elevation={3} sx={{ p: 3, borderRadius: '10px', backgroundColor: '#F7F7F7' }}>
              <Typography variant="h6" sx={{ mb: 2 }}>
                User Details
              </Typography>
              <Typography>ID: {user.id}</Typography>
              <Typography>Name: {user.name}</Typography>
              <Typography>Email: {user.email}</Typography>
              <Typography>Tokens: {user.bamrocTokens}</Typography>
              <Typography>
                Algorithm Access: {user.algorithmAccess ? 'Enabled' : 'Disabled'}
              </Typography>
              {user.companyName && <Typography>Company: {user.companyName}</Typography>}
              <Typography>Created At: {(() => {
                  if (typeof user.createdAt === 'string') {
                    // Handle ISO 8601 string
                    return new Date(user.createdAt).toLocaleString();
                  } else if (user.createdAt?.seconds) {
                    // Handle Firestore Timestamp
                    return new Date(user.createdAt.seconds * 1000).toLocaleString();
                  } else {
                    return 'Invalid Date';
                  }
                })()}</Typography>
            </Paper>
          </Grid>
          {/* Operations */}
          <Grid item xs={12} md={6}>
            {/* Token Operations */}
            <Paper elevation={3} sx={{ p: 3, mb: 3, borderRadius: '10px', backgroundColor: '#F7F7F7' }}>
              <Typography variant="h6" sx={{ mb: 2 }}>
                Token Operations
              </Typography>
              <TextField
                label="Token Amount"
                type="number"
                fullWidth
                value={tokenChange}
                onChange={(e) => setTokenChange(e.target.value)}
                sx={{ mb: 2 }}
              />
              <Button
                variant="contained"
                color="primary"
                sx={{ mr: 2 }}
                onClick={() => handleTokenOperation('add-tokens')}
              >
                Add Tokens
              </Button>
              <Button variant="contained" color="error" onClick={() => handleTokenOperation('deduct-tokens')}>
                Deduct Tokens
              </Button>
            </Paper>
            {/* Algorithm Access */}
            <Paper elevation={3} sx={{ p: 3, borderRadius: '10px', backgroundColor: '#F7F7F7' }}>
              <Typography variant="h6" sx={{ mb: 2 }}>
                Algorithm Access
              </Typography>
              <Button
                variant="contained"
                color="success"
                sx={{ mr: 2 }}
                onClick={() => handleAlgorithmAccess(true)}
              >
                Enable
              </Button>
              <Button variant="contained" color="error" onClick={() => handleAlgorithmAccess(false)}>
                Disable
              </Button>
            </Paper>
          </Grid>
          {/* Token History */}
          <Grid item xs={12}>
            <Paper elevation={3} sx={{ p: 3, borderRadius: '10px', backgroundColor: '#F7F7F7' }}>
              <Typography variant="h6" sx={{ mb: 2 }}>
                Token History
              </Typography>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Date</TableCell>
                      <TableCell>Type</TableCell>
                      <TableCell>Amount</TableCell>
                      <TableCell>Description</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {tokenHistory.map((entry, index) => (
                      <TableRow key={index}>
                        <TableCell>{new Date(entry.date).toLocaleString()}</TableCell>
                        <TableCell>{entry.type}</TableCell>
                        <TableCell>{entry.amount}</TableCell>
                        <TableCell>{entry.description}</TableCell>
                      </TableRow>
                    ))}
                    {tokenHistory.length === 0 && (
                      <TableRow>
                        <TableCell colSpan={4} align="center">
                          No token history available.
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Grid>
          
          {/* Payment History */}
          <Grid item xs={12}>
            <Paper elevation={3} sx={{ p: 3, borderRadius: '10px', backgroundColor: '#F7F7F7' }}>
              <Typography variant="h6" sx={{ mb: 2 }}>
                Payment History
              </Typography>
              <UserPaymentHistoryView email={user.email} />
            </Paper>
          </Grid>

          {/* Project History */}
          <Grid item xs={12}>
            <Paper elevation={3} sx={{ p: 3, borderRadius: '10px', backgroundColor: '#F7F7F7' }}>
              <Typography variant="h6" sx={{ mb: 2 }}>
                Project History
              </Typography>
              <UserProjectHistoryView email={user.email} />
            </Paper>
          </Grid>
          
          {/* Delete User */}
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="error"
              fullWidth
              startIcon={<DeleteIcon />}
              onClick={() => setDeleteDialogOpen(true)}
            >
              Delete User
            </Button>
          </Grid>
        </Grid>
      </Box>

      {/* Delete Confirmation Dialog */}
      <Dialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
        aria-labelledby="delete-dialog-title"
        aria-describedby="delete-dialog-description"
      >
        <DialogTitle id="delete-dialog-title">Delete User</DialogTitle>
        <DialogContent>
          <DialogContentText id="delete-dialog-description">
            Are you sure you want to delete this user? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)}>Cancel</Button>
          <Button
            onClick={handleDeleteUser}
            color="error"
            variant="contained"
            disabled={actionLoading}
          >
            {actionLoading ? <CircularProgress size={20} /> : 'Delete'}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default UserManagement;
