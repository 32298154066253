import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Alert,
} from '@mui/material';

const ProjectHistoryView = () => {
  const [projectHistory, setProjectHistory] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    async function fetchProjectHistory() {
      try {
        const jwt = localStorage.getItem('jwttoken') || sessionStorage.getItem('jwttoken');

        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/userdata/project-history`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        });

        if (!response.ok) {
          throw new Error(`Failed to fetch payment history: ${response.statusText}`);
        }

        const historyData = await response.json();
        if (historyData.success) {
          setProjectHistory(historyData.data); // Assume API response includes a `data` field
        } else {
          throw new Error(historyData.message || 'Error fetching project history.');
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    }

    fetchProjectHistory();
  }, []);

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '60vh' }}>
        <CircularProgress sx={{ color: '#5e92f3' }} />
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '60vh' }}>
        <Alert severity="error">{error}</Alert>
      </Box>
    );
  }

  return (
    <Box sx={{ padding: 3 }}>
      <Typography variant="h4" gutterBottom>
        Project History
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Project ID</TableCell>
              <TableCell>Date</TableCell>
              <TableCell>Project Name</TableCell>
              <TableCell>Clashes Received</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {projectHistory.map((project, index) => (
              <TableRow key={index}>
                <TableCell>{project.projectId}</TableCell>
                <TableCell>{new Date(project.date).toLocaleString()}</TableCell>
                <TableCell>{project.projectName}</TableCell>
                <TableCell>{project.clashesReceived}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default ProjectHistoryView;
