import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Button,
  TextField,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';

const UsersView = () => {
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [filter, setFilter] = useState('');
  const [IdFilter, setIdFilter] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchUsers() {
      try {
        const jwt = localStorage.getItem('jwttoken');
        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/api/admin/adminuserdata/all-users`,
          {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${jwt}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const json = await response.json();

        const uniqueUsers = Array.from(
          new Map(json.data.map((user) => [user.id, user])).values()
        );
        setUsers(uniqueUsers);
        setFilteredUsers(uniqueUsers);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    }

    fetchUsers();
  }, []);

  const handleUserClick = (email) => {
    navigate(`/admin/user/${email}`);
  };

  const handleFilterChange = (e) => {
    const value = e.target.value.toLowerCase();
    setFilter(value);
    const filtered = users.filter(
      (user) =>
        user.name.toLowerCase().includes(value) ||
        user.email.toLowerCase().includes(value)
    );
    setFilteredUsers(filtered);
  };

  const handleIdFilterChange = (e) => {
    const value = e.target.value;
    setIdFilter(value);
  };

  const handleIdFilterClick = () => {
    const filtered = users.filter((user) => user.id == IdFilter);
    setFilteredUsers(filtered);
  };

  if (loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '200px',
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Typography color="error" sx={{ textAlign: 'center', mt: 4 }}>
        {error}
      </Typography>
    );
  }

  return (
    <Box sx={{ p: 3, backgroundColor: '#F7F7F7', borderRadius: '8px' }}>
      {/* Header */}
      <Typography
        variant="h4"
        sx={{
          mb: 1,
          fontWeight: 'bold',
          fontFamily: 'Poppins, sans-serif',
          color: '#5C4033',
        }}
      >
        Users Management
      </Typography>

      {/* Total Users */}
      <Typography
        variant="subtitle1"
        sx={{
          mb: 3,
          fontFamily: 'Poppins, sans-serif',
          color: '#5C4033',
        }}
      >
        Total Users: {users.length}
      </Typography>

      {/* Filter Input */}
      <Box
        sx={{
          mb: 3,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <TextField
          label="Search Users"
          placeholder="Search by name or email"
          fullWidth
          value={filter}
          onChange={handleFilterChange}
          sx={{
            '& .MuiInputBase-input': {
              fontFamily: 'Poppins, sans-serif',
            },
          }}
        />
        <br />
        <Box
          sx={{
            mb: 3,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          <TextField
            label="Search by ID"
            placeholder="Find User by ID"
            fullWidth
            value={IdFilter}
            onChange={handleIdFilterChange}
            sx={{
              '& .MuiInputBase-input': {
                fontFamily: 'Poppins, sans-serif',
              },
            }}
          />
          <br />
          <Button
            onClick={handleIdFilterClick}
            variant="contained"
            endIcon={<SearchIcon />}
          >
            Search By ID
          </Button>
        </Box>
      </Box>

      {/* Users Table */}
      <TableContainer
        component={Paper}
        elevation={3}
        sx={{
          borderRadius: '12px',
          overflow: 'hidden',
          boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
        }}
      >
        <Table>
          <TableHead
            sx={{
              backgroundColor: '#CC9A39',
              '& .MuiTableCell-root': {
                color: '#FFFFFF',
                fontWeight: 'bold',
                fontFamily: 'Poppins, sans-serif',
              },
            }}
          >
            <TableRow>
              <TableCell>User ID</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell align="center">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredUsers.map((user) => (
              <TableRow
                key={user.email}
                sx={{
                  '&:hover': {
                    backgroundColor: '#F0E6D2',
                  },
                }}
              >
                <TableCell sx={{ fontFamily: 'Poppins, sans-serif' }}>
                  {user.id}
                </TableCell>
                <TableCell sx={{ fontFamily: 'Poppins, sans-serif' }}>
                  {user.name}
                </TableCell>
                <TableCell sx={{ fontFamily: 'Poppins, sans-serif' }}>
                  {user.email}
                </TableCell>
                <TableCell align="center">
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: '#CC9A39',
                      color: '#FFFFFF',
                      textTransform: 'capitalize',
                      fontFamily: 'Poppins, sans-serif',
                      fontWeight: '500',
                      '&:hover': {
                        backgroundColor: '#B8860B',
                      },
                    }}
                    onClick={() => handleUserClick(user.email)}
                  >
                    Manage
                  </Button>
                </TableCell>
              </TableRow>
            ))}
            {filteredUsers.length === 0 && (
              <TableRow>
                <TableCell colSpan={3} align="center">
                  <Typography
                    variant="body2"
                    sx={{
                      fontFamily: 'Poppins, sans-serif',
                      color: '#999',
                    }}
                  >
                    No users found.
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default UsersView;
