import React, { useEffect, useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete'; // Solid trash icon
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'; 
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Alert,
  Button
  
} from '@mui/material';

const TokenHistoryView = () => {
  const [tokenHistory, setTokenHistory] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [hovered, setHovered] = useState(false);

  useEffect(() => {
    async function fetchTokenHistory() {
      try {
        const jwt = localStorage.getItem('jwttoken') || sessionStorage.getItem('jwttoken');

        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/userdata/history`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        });

        if (!response.ok) {
          throw new Error(`Failed to fetch token history: ${response.statusText}`);
        }

        const historyData = await response.json();
        setTokenHistory(historyData);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    }

    fetchTokenHistory();
  }, []);

  const handleDelete = () => {
    try {

    }
    catch(e)
    {
      alert("An error occured");
    }
  }
  // Utility function to handle date formatting in DD/MM/YYYY format
  const formatDate = (dateInput) => {
    try {
      if (dateInput && dateInput.seconds !== undefined) {
        // Convert Firestore Timestamp to JavaScript Date
        const date = new Date(dateInput.seconds * 1000); // Convert seconds to milliseconds
        return date.toLocaleString('en-GB', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
        });
      } else if (typeof dateInput === 'string') {
        // Handle case if date is already in string format (like ISO string)
        const date = new Date(dateInput);
        return !isNaN(date.getTime())
          ? date.toLocaleString('en-GB', {
              day: '2-digit',
              month: '2-digit',
              year: 'numeric',
              hour: '2-digit',
              minute: '2-digit',
              second: '2-digit',
            })
          : 'Invalid Date';
      } else {
        return 'Invalid Date';
      }
    } catch (err) {
      return 'Invalid Date';
    }
  };

  if (loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '60vh',
          backgroundColor: '#f7f9fc',
        }}
      >
        <CircularProgress sx={{ color: '#5e92f3' }} /> {/* Soft blue spinner for contrast */}
      </Box>
    );
  }

  if (error) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '60vh',
          backgroundColor: '#f7f9fc',
        }}
      >
        <Alert severity="error" sx={{ fontFamily: 'Poppins, sans-serif', fontWeight: '500' }}>
          {error}
        </Alert>
      </Box>
    );
  }

  return (
    <Box sx={{ padding: 1, backgroundColor: '#ffffff', borderRadius:"10px" , padding:"30px"}}>
      <Box display="flex"justifyContent="space-between">
        <Typography
          variant="h4"
          gutterBottom
          sx={{
            fontSize:"24px",
            fontWeight: 600,
            color: '#000000',
            fontFamily: 'Poppins, sans-serif', 
            mb: 3,
          }}
        >
          Tokens History
        </Typography>
        {/* <DeleteOutlineIcon onClick = {handleDelete} sx={{'&:hover': {cursor:"pointer",color:""}}}/> */}
      </Box>
      <TableContainer
        component={Paper}
        sx={{
          backgroundColor: '#ffffff',
          boxShadow: 'none',
          borderRadius: 2,
          overflowX: 'auto', // Ensure table fits horizontally in smaller screens
        }}
      >
        <Table sx={{ minWidth: 800 }}> {/* Reduced minWidth for better display */}
          <TableHead>
            <TableRow sx={{ backgroundColor: '#e0e0e0' }}>
              <TableCell
                sx={{
                  fontWeight: 600,
                  fontSize: "16px",
                  color: '#636060',
                  fontFamily: 'Poppins, sans-serif',
                  textTransform: 'uppercase',
                  fontSize: '0.9rem',
                  
                }}
              >
                Transaction ID
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: 600,
                  fontSize: "16px",
                  color: '#636060',
                  fontFamily: 'Poppins, sans-serif',
                  textTransform: 'uppercase',
                  fontSize: '0.9rem',
                }}
              >
                Date
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: 600,
                  fontSize: "16px",
                  color: '#636060',
                  fontFamily: 'Poppins, sans-serif',
                  textTransform: 'uppercase',
                  fontSize: '0.9rem',
                }}
              >
                Type
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: 600,
                  fontSize: "16px",
                  color: '#636060',
                  fontFamily: 'Poppins, sans-serif',
                  textTransform: 'uppercase',
                  fontSize: '0.9rem',
                }}
              >
                Amount
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: 600,
                  fontSize: "16px",
                  color: '#636060',
                  fontFamily: 'Poppins, sans-serif',
                  textTransform: 'uppercase',
                  fontSize: '0.9rem',
                }}
              >
                Balance
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: 600,
                  fontSize: "16px",
                  color: '#636060',
                  fontFamily: 'Poppins, sans-serif',
                  textTransform: 'uppercase',
                  fontSize: '0.9rem',
                }}
              >
                Recipient
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: 600,
                  fontSize: "16px",
                  color: '#636060',
                  fontFamily: 'Poppins, sans-serif',
                  textTransform: 'uppercase',
                  fontSize: '0.9rem',
                }}
              >
                Description
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tokenHistory.map((entry, index) => (
              <TableRow
                key={index}
                sx={{
                  '&:nth-of-type(even)': { backgroundColor: '#f0f4f8' },
                  '&:hover': { backgroundColor: '#e3f2fd' },
                }}
              >
                <TableCell
                  sx={{
                    fontFamily: 'Poppins, sans-serif',
                    color: '#636060',
                    fontSize: '14px',
                    fontWeight: '400',
                    lineHeight: "20px",
                    wordBreak: 'break-word', // Allow long text to wrap to a new line
                  }}
                >
                  {entry.transactionId || 'N/A'}
                </TableCell>
                <TableCell
                  sx={{
                    fontFamily: 'Poppins, sans-serif',
                    color: '#636060',
                    fontSize: '14px',
                    fontWeight: '400',
                    lineHeight: "20px",
                  }}
                >
                  {formatDate(entry.date)}
                </TableCell>
                <TableCell
                  sx={{
                    fontFamily: 'Poppins, sans-serif',
                    color: '#636060',
                    fontSize: '14px',
                    fontWeight: '400',
                    lineHeight: "20px",
                  }}
                >
                  {entry.type}
                </TableCell>
                <TableCell
                  sx={{
                    fontFamily: 'Poppins, sans-serif',
                    color: '#636060',
                    fontSize: '14px',
                    fontWeight: '400',
                    lineHeight: "20px",
                  }}
                >
                  {entry.amount}
                </TableCell>

                <TableCell
                  sx={{
                    fontFamily: 'Poppins, sans-serif',
                    color: '#636060',
                    fontSize: '14px',
                    fontWeight: '400',
                    lineHeight: "20px",
                  }}
                >
                  {entry.balance}
                </TableCell>

                <TableCell
                  sx={{
                    fontFamily: 'Poppins, sans-serif',
                    color: '#636060',
                    fontSize: '14px',
                    fontWeight: '400',
                    lineHeight: "20px",
                  }}
                >
                  {entry.recipient || 'N/A'}
                </TableCell>
                <TableCell
                  sx={{
                    fontFamily: 'Poppins, sans-serif',
                    color: '#636060',
                    fontSize: '14px',
                    fontWeight: '400',
                    lineHeight: "20px",
                  }}
                >
                  {entry.description}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default TokenHistoryView;
