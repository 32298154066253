import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Card,
  CssBaseline,
  TextField,
  Typography,
  Link,
  Avatar,
  Grid,
  Divider
} from "@mui/material";
import { Google, AccountCircle, InputOutlined, Microsoft } from "@mui/icons-material"; // Icons for Google and Microsoft
import { useNavigate } from "react-router-dom";
import { auth, googleProvider, microsoftProvider, signInWithPopup } from "../../firebase/firebaseConfig";
import { createUserWithEmailAndPassword, sendEmailVerification, updateProfile } from "firebase/auth";
import logo from "../../assets/logo.jpg"; // Import your logo here
import productImage from "../../assets/5.webp"; // Product Image here
import GoogleIcon from "@mui/icons-material/Google"; // Example for Google
import MicrosoftIcon from "@mui/icons-material/Microsoft";


export default function AdminRegister() {
  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [companyName, setCompanyName] = useState("");


  useEffect(() => {
    const token = localStorage.getItem("jwttoken") || sessionStorage.getItem("jwttoken");
    if (token) {
      // Automatically log in the user and redirect to the dashboard
      navigate("/dashboard");
    }
  }, [navigate]); // The dependency array ensures this runs only once on mount

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const email = data.get("email");
    const name = data.get("name");

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!emailRegex.test(email)) {
      setEmailError("Please enter a valid email address");
      return;
    } else {
      setEmailError("");
    }

    if (password.length < 6) {
      setPasswordError("Password must be at least 6 characters long");
      return;
    } else {
      setPasswordError("");
    }

    if (password !== confirmPassword) {
      setConfirmPasswordError("Passwords do not match");
      return;
    } else {
      setConfirmPasswordError("");
    }

    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
      await updateProfile(user, { displayName: name });
      await sendEmailVerification(user);

      const idToken = await user.getIdToken();

      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/admin/adminuser/admin-register`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${idToken}`,
        },
        body: JSON.stringify({
          email,
          name,
          companyName,
        }),
      });


      if (response.ok) {
        alert("Registration successful! A verification email has been sent to your email address.");
        navigate("/login", { state: { message: "Registration successful! Please log in with your new account." } });
      } else {
        console.error("Failed to add user to Firestore.");
        alert("Error occurred while adding user to Firestore.");
      }
    } catch (error) {
      console.error("Error during registration:", error);
      if (error.code === "auth/email-already-in-use") {
        setEmailError("This email is already in use. Please use a different email.");
      } else {
        alert(error.message);
      }
    }
  };

  useEffect(() => {
    if (confirmPassword && password !== confirmPassword) {
      setConfirmPasswordError("Passwords do not match");
    } else {
      setConfirmPasswordError("");
    }
  }, [password, confirmPassword]);

  const handleOAuthSignUp = async (provider, providerName) => {
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      const idToken = await user.getIdToken();
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/users/oauth-login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${idToken}`,
        },
        body: JSON.stringify({
          companyName,
        }),
      });

      const json = await response.json();
      if (json.success) {
        localStorage.setItem("jwttoken", json.token);
        localStorage.setItem("user", json.displayName);
        localStorage.setItem("email", json.email);
        navigate(`/dashboard`);
      } else {
        alert(`${providerName} Sign-Up failed`);
      }
    } catch (error) {
      console.error(`Error during ${providerName} Sign-Up`, error);
      alert(`${providerName} Sign-Up failed`);
    }
  };

  return (
    <Box
      sx={{
        minHeight: "100vh",
        display: "flex",
        flexDirection: "row",
        alignItems: "stretch",
        width: "100%",
        margin: 0,
        padding: 0,
        overflow: "hidden",
      }}
    >
      <CssBaseline />
      {/* Left Side - Product Image occupying 2/3 */}
      
      <Box
        sx={{
          flex: 5,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
          width: "100%",
          margin: 0,
          padding: 0,

        }}
      >
        <Card
          sx={{
            width: "100%",
            height: "100%",
            borderRadius: 0,
            boxShadow: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#ffffff",
            margin: 0,
          }}
        >
          <Box
            sx={{
              width: "100%",
              maxWidth: 450,
              padding: 3,
              backgroundColor: "#ffffff",
            }}
          >
            <Box display="flex" flexDirection="row" alignItems="center" justifyContent="center"  lineHeight="12px">
              <Avatar src={logo} alt="Product Logo" sx={{ width: 64, height: 64, mb: 2 }} />
              <Typography
                variant="h4"
                component="h1"
                fontWeight={600}
                letterSpacing="2px"
                fontSize="20px"
                color="#CC9A39" // Muted navy blue to match Dashboard theme
                sx={{ fontFamily: "Poppins, sans-serif" }}
                lineHeight="12px"
                gutterBottom
              >
                Vavetek.AI
              </Typography>
            </Box>

            <Box display="flex" flexDirection="column" >
              <Typography
              sx={{
                fontFamily: "Poppins, sans-serif",
                fontSize: "40px",
                fontWeight: 700,
                lineHeight: "48px",
                color: "#212121",
                height:"48px",
                whiteSpace: "nowrap"
              }}>
                Create Your Account
              </Typography>
              <Typography
              sx = {{
                fontFamily: "Poppins, sans-serif",
                fontWeight: 400,
                fontSize: "16px",
                lineHeight: "25.6px",
                textAlign: "center",
                color: "#979797"
              }}>
                  Welcome back! Please enter your details
              </Typography>
            </Box>
            <Button
                onClick={() => handleOAuthSignUp(googleProvider, "Google")}
                variant="outlined"
                size="large"
                startIcon={<GoogleIcon sx = {{color: "inherit"}}/>} // Google Icon
                sx={{
                  width:"100%",
                  fontWeight: 400,
                  fontSize:"17px",
                  fontFamily: "Poppins, sans-serif",
                  color: "#000000", // Google Blue
                  borderColor: "#D6D6D6", // Google Blue Border
                  borderRadius: "10px",
                  padding: "10px 20px", // Custom padding
                  "&:hover": {
                    borderColor: "#005cbf",
                    color: "#005cbf",
                  },
                  marginTop: "15px"
                }}
              >
                Sign up with Google
              </Button>
              <Button
                onClick={() => handleOAuthSignUp(microsoftProvider, "Microsoft")}
                variant="outlined"
                size="large"
                startIcon={<MicrosoftIcon sx = {{color: "inherit"}}/>} // Google Icon
                sx={{
                  width:"100%",
                  fontWeight: 400,
                  fontSize:"17px",
                  fontFamily: "Poppins, sans-serif",
                  color: "#000000", // Google Blue
                  borderColor: "#D6D6D6", // Google Blue Border
                  borderRadius: "10px",
                  padding: "10px 20px", // Custom padding
                  "&:hover": {
                    borderColor: "#005cbf",
                    color: "#005cbf",
                  },
                  marginTop: "15px"
                }}
              >
                Sign up with Microsoft
              </Button>
              <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 3, // Adds spacing between elements
                width: "100%", // Ensures full width
                mt: 2, // Adds vertical margin
                mb: 1
              }}
            >
              {/* Left Line */}
              <Divider sx={{ flex: 1, borderColor: "#D6D6D6", width: "129.43px" }} />

              {/* Middle Text */}
              <Typography
                sx={{
                  color: "#000000", // Gray color for the text
                  fontSize: "13px", // Adjust size as needed
                  fontFamily: "Poppins, sans-serif",
                  lineHeight: "20.8px",
                  fontWeight: 400,
                  letterSpacing: 0.3
                }}
              >
                OR
              </Typography>

              {/* Right Line */}
              <Divider sx={{ flex: 1, borderColor: "#D6D6D6", width: "129.43px" }} />

            </Box>
            <Box component="form" onSubmit={handleSubmit}>
                  <Box display="flex" width="100%" flexDirection="column" alignItems="flex-start">
                    <Typography
                      sx={{ 
                        fontFamily: "Poppins, sans-serif",
                        fontWeight: 400,
                        fontSize: "16px",
                        lineHeight: "25.6px", 
                      }}
                    >
                    Name
                    </Typography>
                    <TextField
                      fullWidth
                      margin="dense"
                      name="name"
                      placeholder="Enter your Name"
                      type="text"
                      variant="outlined"
                      required
                      sx={{
                        width: "100%", // Match width
                        height: "49.83px", // Custom height
                        "& .MuiOutlinedInput-root": {
                          borderRadius: "8px", // Rounded corners
                          borderColor: "#D6D6D6", // Border color
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderWidth: "1px", // Border thickness
                          borderColor: "#D6D6D6", // Outline color
                        },
                      }}
                      InputLabelProps={{
                        style: { color: "#7d7d7d" },
                      }}
                      InputProps={{
                        sx: {
                          height: "50px", // Sets the height explicitly
                        },
                        style: { backgroundColor: "#f8f9fa" }, // Optional background color
                      }}
                    />
                  </Box>
                {/* </Grid> */}

                {/* <Grid item xs={12} sm={6}> */}
                  {/* <TextField
                    fullWidth
                    margin="dense"
                    name="companyname"
                    label="Company Name"
                    type="text"
                    variant="outlined"
                    value={companyName}
                    onChange={(e) => setCompanyName(e.target.value)}
                    InputLabelProps={{
                      style: { color: "#7d7d7d" },
                    }}
                    InputProps={{
                      style: { backgroundColor: "#f8f9fa" },
                    }}
                  /> */}
                {/* </Grid> */}

                {/* Email in full width */}
                {/* <Grid item xs={12}> */}
                  <Box display="flex" width="100%" flexDirection="column" alignItems="flex-start" mt={1}>
                    <Typography
                      sx={{ 
                        fontFamily: "Poppins, sans-serif",
                        fontWeight: 400,
                        fontSize: "16px",
                        lineHeight: "25.6px", 
                      }}
                    >
                    Email
                    </Typography>
                    <TextField
                      fullWidth
                      margin="dense"
                      name="email"
                      placeholder="Enter your Email"
                      type="email"
                      variant="outlined"
                      required
                      error={!!emailError}
                      helperText={emailError}
                      sx={{
                        width: "100%", // Match width
                        height: "49.83px", // Custom height
                        "& .MuiOutlinedInput-root": {
                          borderRadius: "8px", // Rounded corners
                          borderColor: "#D6D6D6", // Border color
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderWidth: "1px", // Border thickness
                          borderColor: "#D6D6D6", // Outline color
                        },
                      }}
                      InputLabelProps={{
                        style: { color: "#7d7d7d" },
                      }}
                      InputProps={{
                        sx: {
                          height: "50px", // Sets the height explicitly
                        },
                        style: { backgroundColor: "#f8f9fa" }, // Optional background color
                      }}
                    />
                  </Box>
                {/* </Grid> */}

                {/* Password and Confirm Password in one row */}
                {/* <Grid item xs={12} sm={6}> */}
                <Box display="flex" width="100%" flexDirection="column" alignItems="flex-start" mt={1}>
                  <Typography
                    sx={{ 
                      fontFamily: "Poppins, sans-serif",
                      fontWeight: 400,
                      fontSize: "16px",
                      lineHeight: "25.6px", 
                    }}
                  >
                  Password
                  </Typography>
                    <TextField
                      fullWidth
                      margin="dense"
                      name="password"
                      placeholder="*******"
                      type="password"
                      variant="outlined"
                      required
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      error={!!passwordError}
                      helperText={passwordError}
                      sx={{
                        width: "100%", // Match width
                        height: "49.83px", // Custom height
                        "& .MuiOutlinedInput-root": {
                          borderRadius: "8px", // Rounded corners
                          borderColor: "#D6D6D6", // Border color
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderWidth: "1px", // Border thickness
                          borderColor: "#D6D6D6", // Outline color
                        },
                      }}
                      InputLabelProps={{
                        style: { color: "#7d7d7d" },
                      }}
                      InputProps={{
                        sx: {
                          height: "50px", // Sets the height explicitly
                        },
                        style: { backgroundColor: "#f8f9fa" }, // Optional background color
                      }}
                    />
                  </Box>
                {/* </Grid> */}

                {/* <Grid item xs={12} sm={6}> */}
                <Box display="flex" width="100%" flexDirection="column" alignItems="flex-start" mt={1}>
                  <Typography
                    sx={{ 
                      fontFamily: "Poppins, sans-serif",
                      fontWeight: 400,
                      fontSize: "16px",
                      lineHeight: "25.6px", 
                    }}
                  >
                  Retype Password
                  </Typography>
                    <TextField
                      fullWidth
                      margin="dense"
                      name="confirmPassword"
                      placeholder="*******"
                      type="password"
                      variant="outlined"
                      required
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      error={!!confirmPasswordError}
                      helperText={confirmPasswordError}
                      sx={{
                        width: "100%", // Match width
                        height: "49.83px", // Custom height
                        "& .MuiOutlinedInput-root": {
                          borderRadius: "8px", // Rounded corners
                          borderColor: "#D6D6D6", // Border color
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderWidth: "1px", // Border thickness
                          borderColor: "#D6D6D6", // Outline color
                        },
                      }}
                      InputLabelProps={{
                        style: { color: "#7d7d7d" },
                      }}
                      InputProps={{
                        sx: {
                          height: "50px", // Sets the height explicitly
                        },
                        style: { backgroundColor: "#f8f9fa" }, // Optional background color
                      }}
                    />
                </Box>
                {/* </Grid>
              </Grid> */}

              <Box mt={3} textAlign="center">
                <Button
                  type="submit"
                  variant="contained"
                  size="large"
                  fullWidth
                  sx={{
                    fontWeight: "bold",
                    fontFamily: "Poppins, sans-serif",
                    background: "#CC9A39",
                    color: "#ffffff",
                    borderRadius: 3,
                    boxShadow: "0px 8px 15px rgba(0, 0, 0, 0.1)",
                    "&:hover": {
                      background: "#CC9A39",
                      boxShadow: "0px 12px 20px rgba(0, 0, 0, 0.2)",
                    },
                  }}
                >
                  Sign Up
                </Button>
              </Box>

              {/* <Box mt={2} textAlign="center">
                <Button
                  onClick={() => handleOAuthSignUp(googleProvider, "Google")}
                  variant="outlined"
                  size="large"
                  fullWidth
                  startIcon={<Google />}
                  sx={{
                    mt: 1.5,
                    fontWeight: "bold",
                    fontFamily: "Poppins, sans-serif",
                    color: "#4285F4",
                    borderColor: "#4285F4",
                    borderRadius: 3,
                    "&:hover": {
                      borderColor: "#005cbf",
                      color: "#005cbf",
                    },
                  }}
                >
                  Sign up with Google
                </Button>
              </Box>

              <Box mt={1.5} textAlign="center">
                <Button
                  onClick={() => handleOAuthSignUp(microsoftProvider, "Microsoft")}
                  variant="outlined"
                  size="large"
                  fullWidth
                  startIcon={<AccountCircle />}
                  sx={{
                    mt: 1.5,
                    fontWeight: "bold",
                    fontFamily: "Poppins, sans-serif",
                    color: "#0078D4",
                    borderColor: "#0078D4",
                    borderRadius: 3,
                    "&:hover": {
                      borderColor: "#005a9e",
                      color: "#005a9e",
                    },
                  }}
                >
                  Sign up with Microsoft
                </Button>
              </Box> */}

              <Box mt={3} textAlign="center">
                <Typography variant="body2" sx={{ fontFamily: "Poppins, sans-serif", color: "#6b6b6b" }}>
                  Already have an account?{" "}
                  <Link href="/login" color="primary" underline="hover">
                    Sign in
                  </Link>
                </Typography>
              </Box>
            </Box>
          </Box>
        </Card>
      </Box>
      {/* Right Side - Register Form occupying 1/3 */}
      <Box
        sx={{
          flex: 5,
          background: `url(${productImage}) center center/cover no-repeat`,
          height: "110mnjpovh",
          width: "100%",
          margin: 0,
        }}
      />
    </Box>
  );
}
