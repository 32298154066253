import React, { useEffect, useState } from 'react';
import { Box, CssBaseline, Container, Toolbar, Typography, Alert, Paper, CircularProgress, Divider } from '@mui/material';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import Sidebar from '../../components/Sidebar';
import ProfileView from './ProfileView';
import TokensView from './TokensView';
import TokenHistoryView from './TokenHistoryView';
import SettingsView from './SettingsView';
import Navbar from '../../components/Navbar'; 
import CustomerSupportView from './CustomerSupportView'; // Import the Customer Support view
import Tutorial from './Tutorial';
import Pricing from './Pricing';
import Products from './Products';
import { useRef } from 'react';
import PaymentHistoryView from './PaymentHistoryView';
import ProjectHistoryView from './ProjectHistoryView';
const Dashboard = () => {
  const [selectedView, setSelectedView] = useState('');
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [name,setName] = useState("");
  const navigate = useNavigate();

  const location = useLocation();
  const successMessage = location.state?.successMessage;
  useEffect(() => {
    if (error) {
      localStorage.removeItem("jwttoken");
      sessionStorage.removeItem("jwttoken");
      navigate('/login', { replace: true });
    }
  }, [error, navigate]);
  useEffect(() => {
    async function fetchData() {
      try {
        const jwt = localStorage.getItem('jwttoken') || sessionStorage.getItem('jwttoken');
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/userdata/profile`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const json = await response.json();
     
        if (json.success) {
          setUserData(json); 
          setName(json.displayName);
        } else {
          setError(json.message || 'Failed to fetch user data');
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    }

    fetchData();
  }, []);

  const renderContent = () => {

    if (!userData) {
      // Render a placeholder or loading spinner if userData is null
      return <div>Loading...</div>; // You can replace this with a loader component
    }
     
    switch (selectedView) {
      case 'profile':
        return <ProfileView userData={userData}/>;
      case 'tokens':
        return <TokensView userData={userData} />;
      case 'history':
        return <TokenHistoryView />;
      case 'paymentHistory':
        return <PaymentHistoryView />;
      case 'projectHistory':
        return <ProjectHistoryView />;
      case 'settings':
        return <SettingsView />;
      case 'support':
        return <CustomerSupportView />;
      case 'tutorial':
        return <Tutorial />
      case 'pricing':
        return <Pricing />
      case 'products':
        return <Products /> 
      default:
        return <Products />;
    }
  };

  if (loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '100vh',
          backgroundColor: '#f7f7f7', // Light gray background for a warm loading page
        }}
      >
        <CircularProgress sx={{ color: '#5e92f3' }} /> {/* Soft blue spinner color for contrast */}
      </Box>
    );
  }

  if (error) {
    return (
      // <Box
      //   sx={{
      //     display: 'flex',
      //     justifyContent: 'center',
      //     alignItems: 'center',
      //     minHeight: '100vh',
      //     backgroundColor: '#f7f7f7', // Light gray for consistency
      //   }}
      // >
      //   <Typography variant="h6" sx={{ color: '#d32f2f', fontFamily: 'Poppins, sans-serif', fontWeight: '600' }}>
      //     Error: {error}
      //   </Typography>
      // </Box>
      null
    );
  }

  return (
    <>
      <CssBaseline />
      <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh', backgroundColor: '#E5E5E5' }}>
        <Navbar setSelectedView = {setSelectedView} name={name} dash={false}/>
        <Toolbar />
        <Box sx={{ display: 'flex', flexGrow: 1, width: '100%' }}>
          <Sidebar onSelect={setSelectedView} selectedView = {selectedView} />
          <Box
            component="main"
            id='main-dashboard'
            sx={{
              flexGrow: 1,
              p: 4,
              backgroundColor: '#E5E5E5', // White for the main content area for a clean look
              borderRadius: 2,
              m: 2,
              width: 'calc(100% - 280px)', // Adjust width based on Sidebar width
            }}
          >
            <Container id="dashboard-container" maxWidth={false}  sx={{ maxWidth: "100%", height: "100%"}}> {/* Use full width container */}
              {successMessage && (
                <Alert
                  severity="success"
                  sx={{
                    mb: 2,
                    borderRadius: 2,
                    backgroundColor: '#e0f7fa', // Light teal for success alert
                    color: '#006064', // Teal for contrast
                    fontFamily: 'Poppins, sans-serif',
                    fontWeight: '500',
                  }}
                >
                  {successMessage}
                </Alert>
              )}
              <Paper
                id = "paper-box-dashboard"
                sx={{
                  p: 4,
                  borderRadius: 3,
                  backgroundColor: '#E5E5E5', // White for a minimalist look
                  color: '#333333', // Dark gray text for readability
                  boxShadow: 'none', // No shadow for a flat design
                  
                  height: "100%"
                }}
              > 
            
                {renderContent()}
              </Paper>
            </Container>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Dashboard;
