import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const useRedirectAfterDelay = (targetPath, delay = 10000) => {
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      navigate(targetPath);
    }, delay);

    return () => clearTimeout(timer); // Cleanup the timer on component unmount
  }, [navigate, targetPath, delay]);
};

export default useRedirectAfterDelay;
