import "./App.css";
import { BrowserRouter as Router, Routes, Route,useNavigate } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./styles/theme";
import Login from "./screens/Login";
import Register from "./screens/Register";
import Dashboard from "./screens/Dashboard/Dashboard";
import PluginLogin from "./screens/PluginLogin";
import ForgotPassword from "./screens/ForgotPassword";
import TransferTokens from "./screens/Dashboard/TransferTokens";
import { useEffect } from "react";
import Tutorial from "./screens/Dashboard/Tutorial";
import AdminRegister from "./screens/Admin/AdminRegister";
import AdminLogin from "./screens/Admin/AdminLogin";
import AdminDashboard from "./screens/Admin/AdminDashboard/AdminDashboard";
import UserManagement from "./screens/Admin/AdminDashboard/UserManagement";
import AddTokens from "./screens/Dashboard/AddTokens";
import PaymentSuccess from "./screens/Dashboard/PaymentRedirects/PaymentSuccess";
import PaymentCancel from "./screens/Dashboard/PaymentRedirects/PaymentCancel";
import PaymentFailure from "./screens/Dashboard/PaymentRedirects/PaymentFailure";

function App() {
  
  return (
    <div className="App">
      <ThemeProvider theme={theme}> {/* Wrap ThemeProvider outside Router */}
        <Router>
          <Routes>
            <Route exact path="/" element={<Login />} />
            <Route exact path="/login" element={<Login />} />
            <Route exact path="/revitlogin" element={<PluginLogin />} />
            <Route exact path="/register" element={<Register />} />
            <Route exact path="/forgotpassword" element={<ForgotPassword />} />
            <Route exact path="/dashboard" element={<Dashboard />} />
              <Route exact path="/dashboard/add-tokens" element={<AddTokens />} />
                <Route path="/payment-success" element={<PaymentSuccess />} />
                <Route path="/payment-cancel" element={<PaymentCancel />} />
                <Route path="/payment-failure" element={<PaymentFailure />} />
              <Route exact path="/dashboard/transfer-tokens" element={<TransferTokens />} />
            <Route exact path="/tutorial/:name/:productName" element={<Tutorial />} />

            {/* Admin Routes */}
            <Route exact path="/admin/register" element={<AdminRegister />} />
            <Route exact path="/admin/login" element={<AdminLogin />} />
            <Route exact path="/admin/dashboard" element={<AdminDashboard />} />
            <Route exact path="/admin/user/:email" element={<UserManagement />} />
            {/* Admin Routes End */}
          </Routes>
        </Router>
      </ThemeProvider>
    </div>
  );
}

export default App;
