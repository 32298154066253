import React, { useState } from 'react';
import {
  Box,
  CssBaseline,
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Button,
  Container,
  Paper,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import AdminSidebar from '../../../components/Admin/AdminSidebar';
import UsersView from './UsersView';
import SupportTicketsView from './SupportTicketsView';
import CouponManagement from './CouponManagement';

const AdminDashboard = () => {
  const [selectedView, setSelectedView] = useState('users');

  const renderContent = () => {
    switch (selectedView) {
      case 'users':
        return <UsersView />;
      case 'supportTickets':
        return <SupportTicketsView />;
      case 'coupons':
        return <CouponManagement />;
      default:
        return (
          <Typography
            variant="h6"
            sx={{ fontFamily: 'Poppins, sans-serif', color: '#555', textAlign: 'center', mt: 2 }}
          >
            Please select an option from the sidebar.
          </Typography>
        );
    }
  };

  return (
    <>
      <CssBaseline />

      {/* Navbar */}
      <AppBar
        position="fixed"
        elevation={2}
        sx={{
          backgroundColor: '#FFFFFF',
          color: '#2C2A2A',
          height: '80px',
          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
      >
        <Toolbar>
          <IconButton edge="start" color="inherit" aria-label="menu" sx={{ mr: 2 }}>
            <MenuIcon />
          </IconButton>
          <Typography
            variant="h6"
            sx={{
              flexGrow: 1,
              fontFamily: 'Poppins, sans-serif',
              fontWeight: 600,
              letterSpacing: '1px',
              color: '#CC9A39',
            }}
          >
            Admin Dashboard
          </Typography>
          <Button
            variant="contained"
            sx={{
              backgroundColor: '#CC9A39',
              color: '#FFFFFF',
              fontFamily: 'Poppins, sans-serif',
              fontWeight: 500,
              textTransform: 'none',
              '&:hover': {
                backgroundColor: '#B8860B',
              },
            }}
            onClick={() => {
              localStorage.removeItem('jwttoken');
              sessionStorage.removeItem('jwttoken');
              window.location.href = '/login';
            }}
          >
            Logout
          </Button>
        </Toolbar>
      </AppBar>

      {/* Main Layout */}
      <Box sx={{ display: 'flex', marginTop: '80px', minHeight: '100vh' }}>
        {/* Sidebar */}
        <AdminSidebar
          options={[
            { label: 'Users', value: 'users' },
            { label: 'Support Tickets', value: 'supportTickets' },
            { label: 'Coupons', value: 'coupons' }
          ]}
          selectedView={selectedView}
          onSelect={setSelectedView}
        />

        {/* Content Area */}
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            p: 4,
            backgroundColor: '#F7F7F7',
            borderRadius: 2,
            margin: 2,
            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
          }}
        >
          <Container
            maxWidth={false}
            sx={{
              maxWidth: '100%',
              height: '100%',
              padding: 2,
            }}
          >
            <Paper
              elevation={3}
              sx={{
                padding: 3,
                backgroundColor: '#FFFFFF',
                borderRadius: '15px',
                boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
              }}
            >
              {renderContent()}
            </Paper>
          </Container>
        </Box>
      </Box>
    </>
  );
};

export default AdminDashboard;
