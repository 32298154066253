import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  TextField,
  Button,
  CircularProgress,
  Paper,
  Alert,
  Grid,
} from '@mui/material';

const SupportTicketsView = () => {
  const [supportTickets, setSupportTickets] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [filterType, setFilterType] = useState('all'); // 'all', 'email', or 'userId'
  const [filterValue, setFilterValue] = useState('');

  useEffect(() => {
    if (filterType === 'all') {
      fetchSupportTickets();
    }
  }, [filterType]);

  const fetchSupportTickets = async () => {
    try {
      setLoading(true);
      const jwt = localStorage.getItem('jwttoken');
      let endpoint = `${process.env.REACT_APP_API_BASE_URL}/api/admin/adminsupportticket/support-tickets`;

      if (filterType === 'email' && filterValue) {
        endpoint = `${process.env.REACT_APP_API_BASE_URL}/api/admin/adminsupportticket/support-tickets/by-email/${filterValue}`;
      } else if (filterType === 'userId' && filterValue) {
        endpoint = `${process.env.REACT_APP_API_BASE_URL}/api/admin/adminsupportticket/support-tickets/by-user-id/${filterValue}`;
      }

      const response = await fetch(endpoint, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      });

      if (!response.ok) {
        throw new Error(`Failed to fetch support tickets: ${response.statusText}`);
      }

      const json = await response.json();
      setSupportTickets(json.supportTickets || []);
      setError(null);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleFilterChange = (type) => {
    setFilterType(type);
    setFilterValue('');
    setSupportTickets([]);
    if (type === 'all') {
      fetchSupportTickets();
    }
  };

  const renderTickets = () => {
    if (loading) {
      return (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '200px',
          }}
        >
          <CircularProgress />
        </Box>
      );
    }

    if (error) {
      return (
        <Alert severity="error" sx={{ mb: 2, borderRadius: '10px' }}>
          {error}
        </Alert>
      );
    }

    if (supportTickets.length === 0) {
      return (
        <Typography variant="body1" sx={{ textAlign: 'center', color: '#888', mt: 2 }}>
          No support tickets found.
        </Typography>
      );
    }

    return (
      <Grid container spacing={3}>
        {supportTickets.map((ticket, index) => (
          <Grid item xs={12} md={6} key={index}>
            <Paper
              elevation={3}
              sx={{
                p: 3,
                borderRadius: 3,
                backgroundColor: '#FBF3E4',
                boxShadow: '0px 6px 12px rgba(0,0,0,0.1)',
                transition: 'transform 0.3s',
                '&:hover': {
                  transform: 'translateY(-5px)',
                },
              }}
            >
              <Typography variant="h6" sx={{ mb: 1, fontWeight: 'bold', color: '#8B4513' }}>
                Ticket ID: {ticket.id}
              </Typography>
              <Typography variant="body2" sx={{ mb: 1, color: '#5C4033' }}>
                <strong>User Email:</strong> {ticket.userEmail || 'N/A'}
              </Typography>
              <Typography variant="body2" sx={{ mb: 1, color: '#5C4033' }}>
                <strong>Subject:</strong> {ticket.subject}
              </Typography>
              <Typography variant="body2" sx={{ mb: 1, color: ticket.status === 'Resolved' ? '#388E3C' : '#F57C00' }}>
                <strong>Status:</strong> {ticket.status || 'Pending'}
              </Typography>
              <Typography variant="body2" sx={{ mb: 1, color: '#5C4033' }}>
                <strong>Created At:</strong>{' '}
                {(() => {
                  if (typeof ticket.createdAt === 'string') {
                    // Handle ISO 8601 string
                    return new Date(ticket.createdAt).toLocaleString();
                  } else if (ticket.createdAt?.seconds) {
                    // Handle Firestore Timestamp
                    return new Date(ticket.createdAt.seconds * 1000).toLocaleString();
                  } else {
                    return 'Invalid Date';
                  }
                })()}
              </Typography>
              <Typography variant="body2" sx={{ color: '#5C4033' }}>
                <strong>Description:</strong> {ticket.description}
              </Typography>
            </Paper>
          </Grid>
        ))}
      </Grid>
    );
  };

  return (
    <Box sx={{ p: 4 }}>
      <Typography variant="h4" sx={{ mb: 3, fontWeight: 'bold', color: '#000000' }}>
        Support Tickets
      </Typography>

      <Box sx={{ mb: 4, display: 'flex', gap: 2, flexWrap: 'wrap' }}>
        <Button
          variant={filterType === 'all' ? 'contained' : 'outlined'}
          onClick={() => handleFilterChange('all')}
          sx={{
            backgroundColor: filterType === 'all' ? '#CC9A39' : '#ffffff',
            color: filterType === 'all' ? '#ffffff' : '#000000',
            '&:hover': { backgroundColor: '#B58B30' },
          }}
        >
          All Tickets
        </Button>
        <Button
          variant={filterType === 'email' ? 'contained' : 'outlined'}
          onClick={() => handleFilterChange('email')}
          sx={{
            backgroundColor: filterType === 'email' ? '#CC9A39' : '#ffffff',
            color: filterType === 'email' ? '#ffffff' : '#000000',
            '&:hover': { backgroundColor: '#B58B30' },
          }}
        >
          Filter by Email
        </Button>
        <Button
          variant={filterType === 'userId' ? 'contained' : 'outlined'}
          onClick={() => handleFilterChange('userId')}
          sx={{
            backgroundColor: filterType === 'userId' ? '#CC9A39' : '#ffffff',
            color: filterType === 'userId' ? '#ffffff' : '#000000',
            '&:hover': { backgroundColor: '#B58B30' },
          }}
        >
          Filter by User ID
        </Button>
      </Box>

      {(filterType === 'email' || filterType === 'userId') && (
        <Box sx={{ mb: 3, display: 'flex', gap: 2 }}>
          <TextField
            label={filterType === 'email' ? 'User Email' : 'User ID'}
            fullWidth
            value={filterValue}
            onChange={(e) => setFilterValue(e.target.value)}
          />
          <Button
            variant="contained"
            onClick={fetchSupportTickets}
            disabled={!filterValue}
            sx={{
              backgroundColor: '#CC9A39',
              color: '#ffffff',
              '&:hover': { backgroundColor: '#B58B30' },
            }}
          >
            Apply Filter
          </Button>
        </Box>
      )}

      {renderTickets()}
    </Box>
  );
};

export default SupportTicketsView;
