import React from 'react';
import { Box, Typography } from '@mui/material';
import useRedirectAfterDelay from '../../hooks/useRedirectAfterDelay';

const PaymentCancel = () => {
  useRedirectAfterDelay('/dashboard/tokens-overview', 10000); // Redirect after 10 seconds

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '30px',
        backgroundColor: '#FFFFFF',
        borderRadius: '10px',
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
        maxWidth: '500px',
        margin: 'auto',
        marginTop: '50px',
      }}
    >
      <Typography
        variant="h4"
        sx={{
          fontWeight: '600',
          color: '#FF9800',
          fontFamily: 'Poppins, sans-serif',
          marginBottom: '20px',
        }}
      >
        Payment Cancelled
      </Typography>
      <Typography
        variant="body1"
        sx={{
          color: '#777676',
          fontFamily: 'Poppins, sans-serif',
          marginBottom: '20px',
          textAlign: 'center',
        }}
      >
        You cancelled the payment process. You will be redirected to the Tokens Overview page in 10 seconds.
      </Typography>
    </Box>
  );
};

export default PaymentCancel;
