import React from 'react';
import { Box, List, ListItem, ListItemText, Typography } from '@mui/material';

const AdminSidebar = ({ options, selectedView, onSelect }) => {
  return (
    <Box
      sx={{
        width: 280,
        minHeight: '100vh',
        backgroundColor: '#FAF3E8', // Soft beige background
        borderRight: '1px solid #E0E0E0', // Subtle border for separation
        padding: 3,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      {/* Sidebar Header */}
      <Box>
        <Typography
          variant="h5"
          sx={{
            mb: 4,
            color: '#CC9A39', // Accent color for the header
            fontWeight: 'bold',
            fontFamily: 'Poppins, sans-serif',
            textAlign: 'center',
          }}
        >
          Admin Menu
        </Typography>

        {/* Sidebar Options */}
        <List>
          {options.map((option) => (
            <ListItem
              key={option.value}
              button
              onClick={() => onSelect(option.value)}
              selected={selectedView === option.value}
              sx={{
                borderRadius: 2,
                mb: 1,
                backgroundColor: selectedView === option.value ? '#CC9A39' : 'transparent',
                color: selectedView === option.value ? '#FFFFFF' : '#5C4033',
                '&:hover': {
                  backgroundColor: selectedView === option.value ? '#B58B30' : '#F0E6D2',
                  color: selectedView === option.value ? '#FFFFFF' : '#5C4033',
                },
                transition: 'all 0.3s ease-in-out',
              }}
            >
              <ListItemText
                primary={option.label}
                primaryTypographyProps={{
                  fontSize: '1rem',
                  fontWeight: selectedView === option.value ? 700 : 500,
                  fontFamily: 'Poppins, sans-serif',
                }}
              />
            </ListItem>
          ))}
        </List>
      </Box>

      {/* Footer */}
      <Box sx={{ textAlign: 'center', mt: 'auto' }}>
        <Typography
          variant="body2"
          sx={{
            fontFamily: 'Poppins, sans-serif',
            fontSize: '0.9rem',
            color: '#9E7B57',
          }}
        >
          © 2024 Admin Dashboard
        </Typography>
      </Box>
    </Box>
  );
};

export default AdminSidebar;
