import React, { useState,useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Box,
  Typography,
  TextField,
  Button,
  Card,
  Link,
  Avatar,
  Grid,
  CssBaseline,
  Alert,
  InputAdornment,
  IconButton,
  Checkbox
} from "@mui/material";
import { auth } from "../../firebase/firebaseConfig";
import { signInWithEmailAndPassword, sendEmailVerification, fetchSignInMethodsForEmail } from "firebase/auth";
import logo from "../../assets/logo.jpg"; // Import the logo image
import productImage from "../../assets/4.webp"; // Add an image related to your product
import "@fontsource/dm-sans"; // Import DM Sans
import { MailOutline } from "@mui/icons-material";
import { LockOutlined, Visibility, VisibilityOff } from "@mui/icons-material";


export default function AdminLogin() {
  const [showPassword,setShowPassword] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [showDisplayNameForm, setShowDisplayNameForm] = useState(false);
  const [newDisplayName, setNewDisplayName] = useState("");

  const successMessage = location.state?.message || "";

  useEffect(() => {
    const token = localStorage.getItem("jwttoken");
    if (token) {
      // Automatically log in the user and redirect to the dashboard
      navigate("/admin/dashboard");
    }
  }, [navigate]); // The dependency array ensures this runs only once on mount


  const handleTogglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  }; 


  
  
  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const email = data.get("email");
    const password = data.get("password");

    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      if (user.emailVerified) {
        const idToken = await user.getIdToken();
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/admin/adminuser/admin-login`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${idToken}`,
          },
        });
        const json = await response.json();
        console.log(json);
        if (json.success) {
          
          localStorage.setItem("jwttoken", json.token || idToken);
          localStorage.setItem("user", json.displayName || user.displayName || "Anonymous");
          localStorage.setItem("email", json.email || user.email);
          
          navigate(`/admin/dashboard`);
        } else {
          alert("Failed to log in");
        }
      } else {
        alert("Please verify your email before logging in.");
        if (window.confirm("Would you like us to resend the verification email?")) {
          await sendEmailVerification(user);
          alert("Verification email resent. Please check your inbox.");
        }
      }
    } catch (error) {
      if (error.code === "auth/too-many-requests") alert("Too many requests. Please try again later");
      else alert("Invalid Credentials");
    }
  };

  return (
    <Box
      sx={{
        minHeight: "100vh",
        display: "flex",
        flexDirection: "row",
        alignItems: "stretch",
        width: "100%",
        margin: 0,
      }}
    >
      <CssBaseline />
      
      {/* Left Side - Login Form */}
      <Box
        sx={{
          flex: 5,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
          margin: 0,
          padding: 0,
        }}
      >
        <Card
          sx={{
            width: "460.87px",
            height: "100%",
            borderRadius: 0,
            boxShadow: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#ffffff",
          }}
        >
          <Box
            sx={{
              width: "100%",
              // maxWidth: 450,
              padding: 4,
              backgroundColor: "#ffffff",
            }}
          >
            <Box display="flex" flexDirection="row" alignItems="center" mb={3} gap={2} lineHeight="12px">
              <Avatar src={logo} alt="Product Logo" sx={{ width: 64, height: 64, mb: 2 }} />
              <Typography
                variant="h4"
                component="h1"
                fontWeight={600}
                letterSpacing="2px"
                fontSize="20px"
                color="#CC9A39" // Muted navy blue to match Dashboard theme
                sx={{ fontFamily: "Poppins, sans-serif" }}
                lineHeight="12px"
                gutterBottom
              >
                Vavetek.AI
              </Typography>
            </Box>
            <Box
              sx={{
                display: "inline-block", 
                lineHeight: "44.4px",
                width: "100%",
                textAlign: "left"
              }}
            >
              <Typography
                component="span"
                variant="h5"
                sx={{
                  fontFamily: "DM Sans, sans-serif",
                  fontWeight: 700,
                  fontSize: "37px",
                  lineHeight: "44.4px",
                  color: "#000000", // Black color for "Log in to"
                }}
              >
                Log in to{" "}
              </Typography>
              <Typography
                component="span"
                variant="h5"
                sx={{
                  fontFamily: "DM Sans, sans-serif",
                  fontWeight: 700,
                  fontSize: "37px",
                  lineHeight: "44.4px",
                  color: "#CF9D3F", // Gold color for "VAVETEK.AI"
                }}
              >
                VAVETEK.AI ADMINISTRATOR
              </Typography>
            </Box>
            {successMessage && (
              <Alert severity="success" sx={{ mb: 2 }}>
                {successMessage}
              </Alert>
            )}
            <Box 
              sx={{
                display: "flex", // Enables Flexbox
                alignItems: "center", // Vertically centers content
                justifyContent: "left", // Horizontally centers content (optional) 
                lineHeight: "27.2px",
                width: "100%",
                textAlign: "left",
                marginTop: "10px"
              }}>
            </Box>

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 3, // Adds spacing between elements
                width: "100%", // Ensures full width
                my: 3, // Adds vertical margin
              }}
            >
            </Box>

            
              <Box component="form" onSubmit={handleSubmit}>
                <TextField
                  name="email" 
                  type="email"
                  variant="outlined"
                  placeholder="Email"
                  required
                  sx={{
                    width: "100%", // Match width
                    height: "55.74px", // Custom height
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "10px", // Rounded corners
                      borderColor: "#D6D6D6", // Border color
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderWidth: "1px", // Border thickness
                      borderColor: "#D6D6D6", // Outline color
                    },
                  }}
                  InputLabelProps={{
                    style: { color: "#7d7d7d" }, // Label color
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <MailOutline sx={{ color: "#D3D3D3" }} /> 
                      </InputAdornment>
                    ),
                    style: {
                      backgroundColor: "#f8f9fa", // Input field background color
                    },
                  }}
                />

                <TextField
                  fullWidth
                  margin="normal"
                  name="password" 
                  type={showPassword ? "text" : "password"} // Toggles between "text" and "password"
                  placeholder="Password"
                  variant="outlined"
                  required
                  sx={{
                    width: "100%", // Match width
                    height: "55.74px", // Custom height
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "10px", // Rounded corners
                      borderColor: "#D6D6D6", // Border color
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderWidth: "1px", // Border thickness
                      borderColor: "#D6D6D6", // Outline color
                    },
                  }}
                  InputLabelProps={{
                    style: { color: "#7d7d7d" }, // Label color
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LockOutlined sx={{ color: "#D3D3D3" }} /> 
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleTogglePasswordVisibility}
                          edge="end"
                          aria-label="toggle password visibility"
                        >
                          {showPassword ? (
                            <VisibilityOff sx={{ color: "#D3D3D3" }} />
                          ) : (
                            <Visibility sx={{ color: "#D3D3D3" }} />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                    style: {
                      backgroundColor: "#f8f9fa", // Input field background color
                    },
                  }}
                  
                />

                <Box 
                  mt={2}  
                  display="flex"
                  flexDirection="row"
                  width="100%"
                  justifyContent="space-between"
                  alignItems="center" // Ensures vertical alignment
                  >
                   <Box
                      display="flex"
                      flexDirection="row"
                      alignItems="center" // Ensures vertical alignment
                    >
                  </Box>

                  <Link href="/forgotpassword" color="secondary" variant="body2" underline="hover">
                    <Typography
                        component="span"
                        sx={{
                          fontFamily: "Poppins, sans-serif",
                          fontWeight:500,
                          fontSize: "15px",
                          color: "#3352EC",
                          lineHeight: "24px"
                        }}
                      > 
                      Forgot password?
                    </Typography>
                  </Link>
                </Box>

                <Box mt={4} textAlign="center">
                  <Button
                    type="submit"
                    variant="contained"
                    size="large"
                    fullWidth
                    sx={{
                      width: "100%",
                      height: "58.07px", 
                      background: "#CC9A39",
                      borderRadius: "10px",
                      boxShadow: "0px 8px 15px rgba(0, 0, 0, 0.1)",
                      "&:hover": {
                        boxShadow: "0px 12px 20px rgba(0, 0, 0, 0.2)",
                      },
                    }}
                  >
                    <Typography sx={{
                      fontFamily: "Poppins, sans-serif",
                      fontWeight: "600",
                      fontSize: "20px",
                      
                    }}>
                      Log in
                    </Typography>
                  </Button>
                </Box>
              </Box>
          </Box>
        </Card>
      </Box>

      {/* Right Side - Product Image */}
      <Box
        sx={{
          flex: 5,
          background: `url(${productImage}) center center/cover no-repeat`,
          height: "100vh",
        }}
      />
    </Box>
  );
}
