import React, { useState } from 'react';
import { Drawer, List, ListItem, ListItemIcon, ListItemText, Toolbar, Typography ,Box,Divider} from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import TokenIcon from '@mui/icons-material/Token';
import HistoryIcon from '@mui/icons-material/History';
import SettingsIcon from '@mui/icons-material/Settings';
import SupportIcon from '@mui/icons-material/Support';
import DownloadIcon from '@mui/icons-material/Download';
import ReceiptIcon from '@mui/icons-material/Receipt';
import WorkHistoryIcon from '@mui/icons-material/WorkHistory';

const Sidebar = ({ onSelect,selectedView }) => {
  const [selectedItem, setSelectedItem] = useState('profile');

  const handleSelect = (view) => {
    setSelectedItem(view);
    onSelect(view);
  };

  return (
    <Drawer 
      variant="permanent"
      anchor="left"
      sx={{
        // paddingTop:"200px",
        width: 280,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: 300,
          boxSizing: 'border-box',
          bgcolor: '#FBF3E4', // Light beige background for a soft, elegant look
          color: '#5c4033', // Dark brown text color for good contrast 
          paddingTop: "30px",
          boxShadow: 'none',
        },
      }}
    >
      <Toolbar>
        <Typography
          variant="h5"
          sx={{
            color: '#8b4513', // SaddleBrown color for a warm accent
            fontFamily: 'Poppins, sans-serif',
            fontWeight: 700,
            textTransform: 'uppercase',
            letterSpacing: '0.15em',
            margin: '0 auto',
            pt: 2,
          }}
        >
          Dashboard
        </Typography>
      </Toolbar>
      <List>
        {[
          { label: 'Profile', icon: <PersonIcon />, value: 'profile' },
          { label: 'Token Overview', icon: <TokenIcon />, value: 'tokens' },
          { label: 'Token History', icon: <HistoryIcon />, value: 'history' },
          { label: 'Payment History', icon: <ReceiptIcon />, value: 'paymentHistory' },
          { label: 'Project History', icon: <WorkHistoryIcon />, value: 'projectHistory' },
          // { label: 'Settings', icon: <SettingsIcon />, value: 'settings' },
          { label: 'Customer Support', icon: <SupportIcon />, value: 'support' },
        ].map((item) => (
          <ListItem
            button
            key={item.value}
            selected={selectedView === item.value}
            onClick={() => handleSelect(item.value)}
            sx={{
              color: selectedView === item.value ? '#000000' : '#000000', // Text color
              borderRadius: '10px', // Fully round buttons
              mx: 'auto', // Center horizontally in the Drawer
              my: 1, // Vertical spacing between buttons
              width: '260px', // Set width for circular appearance
              height: '60px', // Set height for circular appearance
              display: 'flex', // Ensure proper alignment
              alignItems: 'center', // Vertically align content
              justifyContent:"flex-start", // Horizontally align content
              transition: 'background-color 0.3s, color 0.3s',
              '&:hover': {
                backgroundColor: '#CC9A39', // Lighter beige for hover effect
                color: '#000000', // Hover text color
                fontWeight: '500',
                cursor:"pointer"
              },
              '&.Mui-selected': {
                backgroundColor: '#d2b48c', // Selected item background color
                color: '#8b4513', // Selected text color
                '&:hover': {
                  backgroundColor: '#c8ad7f', // Slightly darker tan for hover while selected
                },
              },
            }}
          >
            <ListItemIcon
              sx={{
                color: selectedView === item.value ? '#8b4513' : '#9e7b57',
                minWidth: 0, // Reduce icon padding
                width: '40px', // Ensure icon fits within circular button
                height: '40px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {item.icon}
            </ListItemIcon>
            <ListItemText
              primary={item.label}
              primaryTypographyProps={{
                fontSize: '1.1rem', // Slightly larger font for a more stylish look
                fontWeight: selectedView === item.value ? '700' : '500', // Extra bold for selected items for emphasis
                fontFamily: 'Poppins, sans-serif', // Use Poppins for consistency and style
                textTransform: 'capitalize', // Make labels more aesthetically pleasing with capitalization
                letterSpacing: '0.05em', // Add slight letter spacing for a more premium look
                lineHeight: '1.6', // Increase line height for better readability and elegance
              }}
            />
          </ListItem>
        ))}
      </List>
      <Box display = "flex" flexDirection = "column" sx={{
        height:"100%",
        width:"100%",
        justifyContent:"space-between",
        alignItems:"center"
      }}>
        <Box display = "flex" flexDirection="column" alignItems="center" justifyContent="flex-end"  sx={{
          height: "100%",
          width:"100%",
          marginBottom:"20px",
          gap:2
        }}>
        <Divider 
          sx={{
            width: "100%", // Explicitly set width
            // borderWidth: '3px', // Increase thickness
            borderColor: '#9E9E9E', // Optional: Change color

          }}
        />
        <Box display="flex" flexDirection="row" gap={1}>
          <DownloadIcon sx={{
            color:"#CC9A39",
            height: "40px",
            width: "40px"
          }} />
          <Typography
              alignItems="center"
              justifyContent="center"
              sx={{
                color: "#CC9A39",
                fontSize: "16px",
                lineHeight: "12px",
                fontFamily: "Poppins, sans-serif",
                display: "flex",
                alignItems: "center", // Center text within the Typography if it spans multiple lines
                cursor: "pointer", // Make it look clickable
                textDecoration: "none" // Optional: remove underline
              }}
              component="a"
              href="/assets/BAMROC_Setup.zip" // Adjust this to match your file path
              download
            >
              Client App
            </Typography>
          </Box>
        </Box>
      </Box>
    </Drawer>
  );
};

export default Sidebar;
